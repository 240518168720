<template>
  <div class="home">
  <!--  <meet
      v-if="isJoinMeeting"
      :channel="channel"
      :appid="appid"
      :token="token"
      :uid="uid"
      :preMute="config.mute"
      :preCameraOff="config.cameraOff"
      @leave-meeting="handleLeaveMeeting"
    />
    <main-page
      v-else
      @join-meeting="handleJoinMeeting"
      :channel="channel"
      :appid="appid"
      :token="token"
      :uid='uid'
    />-->
    <meet
      v-if="token"
      :channel="channel"
      :appid="appid"
      :token="token"
      :uid="uid"
      :preMute="false"
      :preCameraOff="false"
      @leave-meeting="handleLeaveMeeting"
    />
  </div>
</template>

<script>
import Meet from "@/components/Meet.vue";
import MainPage from "@/components/Main.vue";
import { APPID, CHANNELNAME, UID } from '@/config/index'

export default {
  name: "Home",
  components: {
    Meet,
    MainPage
  },
  data() {
    return {
      isJoinMeeting: false,
      config: null,
      appid: localStorage.getItem("appid") || APPID,
      channel: sessionStorage.getItem("channel") || CHANNELNAME,
      uid: sessionStorage.getItem("uid") || UID,
      token:
        localStorage.getItem("token") === "null"
          ? null
          : localStorage.getItem("token") === null
          ? undefined
          : localStorage.getItem("token")
    };
  },
  watch: {
    appid: {
      immediate: true,
      handler(newV) {
     //   alert(1)
     //   alert(newV)
      /*  if (!newV || newV === "null" || newV === undefined) {
          const appid = window.prompt("Input your appid:");
          if (appid) {
            localStorage.setItem("appid", appid);
            this.appid = appid;
          } else {
            window.location.reload();
          }
        }*/
        localStorage.setItem("appid", APPID);
        this.appid = APPID
     //  localStorage.setItem("appid", "a6477ac9795d4bdbb4e172c7ab369691");
       // this.appid = "a6477ac9795d4bdbb4e172c7ab369691"
      }
    },
    channel: {
      immediate: true,
      handler(newV) {
      //  alert(2)
       /* if (!newV || newV === "null" || newV === undefined) {
          const channel = window.prompt("Input your room channel:");
          if (channel) {
            sessionStorage.setItem("channel", channel);
            this.channel = channel;
          } else {
            window.location.reload();
          }
        }*/
       sessionStorage.setItem("channel", CHANNELNAME);
        this.channel = CHANNELNAME
      }
    },
    uid: {
      immediate: true,
      handler(newV) {
     //   alert(3)
      //  alert(newV)
        /*if (!newV || newV === "null" || newV === undefined) {
          const uid = window.prompt("Input your uid:");
          if (uid) {
            localStorage.setItem("uid", uid);
            this.uid = uid;
          } else {
            window.location.reload();
          }
        }*/
       localStorage.setItem("uid", UID);
        this.uid = UID
      }
    },
    token: {
      immediate: true,
      handler(newV) {


      //  alert(newV)
       //    this.token = newV
     //   alert('token')
      //  alert(newV)
       /* if (!newV && newV !== null) {
          const token = window.prompt("Input your token:");
          if (token) {
            localStorage.setItem("token", token);
            this.token = token === "null" ? null : token === null ? undefined : token;
          } else {
            window.location.reload();
          }
        }*/

     //    localStorage.setItem("token",'006a6477ac9795d4bdbb4e172c7ab369691IABo4wrj0mxbh1BpsIgPoEM3YLOJBh2rCIl74IM3Zv0XCB3OGvH3yPnbIgAYLIdLqscPZwQAAQA6hA5nAgA6hA5nAwA6hA5nBAA6hA5n')
      //   this.token = '006a6477ac9795d4bdbb4e172c7ab369691IABo4wrj0mxbh1BpsIgPoEM3YLOJBh2rCIl74IM3Zv0XCB3OGvH3yPnbIgAYLIdLqscPZwQAAQA6hA5nAgA6hA5nAwA6hA5nBAA6hA5n'
      console.log('-------newV', newV)  

      if (!newV && !this.$globaltoken) {
        const tokentemporary = window.prompt("token失效，请重新输入:");
        if (tokentemporary) {
          this.$globaltoken = tokentemporary
          localStorage.setItem("token", this.$globaltoken)
          this.token = this.$globaltoken
          } else {
            this.$globaltoken = tokentemporary
          localStorage.setItem("token", this.$globaltoken)
          this.token = this.$globaltoken
            this.$router.push('/')
          }
      } else{
        this.$globaltoken = newV;
        // localStorage.setItem("token", this.$globaltoken)
      }
      // localStorage.setItem("token", this.$globaltoken)
        // this.token = this.$globaltoken
     //   localStorage.setItem("token", '007eJxTYDBU3h67mFPQ7ot0pOgJ37YVj01/a0eYWh9pzI5Iy5V75a7AkGhmYm6emGxpbmmaYpKUkpRkkmpobpRsnphkbGZpZmn4JZUvvSGQkaFtyQNGRgYIBPG5GCoy89IrU+ML8pMZGABTyCAj');
      //   this.token = '007eJxTYDBU3h67mFPQ7ot0pOgJ37YVj01/a0eYWh9pzI5Iy5V75a7AkGhmYm6emGxpbmmaYpKUkpRkkmpobpRsnphkbGZpZmn4JZUvvSGQkaFtyQNGRgYIBPG5GCoy89IrU+ML8pMZGABTyCAj'
     }
    }
  },
  methods: {
    handleJoinMeeting(config) {
      this.isJoinMeeting = true;
      this.config = config;
    },
    handleLeaveMeeting() {
      this.isJoinMeeting = false;
    }
  },

};
</script>
