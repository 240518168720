import { render, staticRenderFns } from "./close-button.vue?vue&type=template&id=31911dc0&scoped=true"
import script from "./close-button.vue?vue&type=script&lang=js"
export * from "./close-button.vue?vue&type=script&lang=js"
import style0 from "./close-button.vue?vue&type=style&index=0&id=31911dc0&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31911dc0",
  null
  
)

export default component.exports