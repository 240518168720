<template>
 <div>
    <div class="bg">
         <div class="header">
             <img  class="imgxy" src="../assets/h5/bank.png" alt="" />
          </div>
          <div>
            <progress-bar ref="probar"  @click5="click5"  :network="network" ></progress-bar>
          </div>
          <prompt :promptc="promptflag" :reminddata1="reminddata1" :reminddata2="reminddata2" @retry="retry"></prompt>
    </div>
 </div>

</template>

<script>
import ProgressBar from "@/components/progress-bar/main.vue";
import Prompt from "@/components/prompt/main.vue";
import { FETCHURL,APPID,APPCertificate,CHANNELNAME,UID } from '@/config/index'
import axios from 'axios';
export default {
  name: "Index",
  data(){
    return {
       messagedata:'',
       promptflag:false,
       network:false,
       networkToast:null,
       reminddata1:'',
       reminddata2:'',
       timer:null
    }
  },
  components: {
    ProgressBar,
    Prompt
  },
  mounted(){
        const data = {
            "appId": APPID,
            "appCertificate": APPCertificate,
            "channelName": CHANNELNAME,
            "uid": UID
        };
        axios.post(FETCHURL, data,{
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
      //   alert(5)
          console.info('tokentokentokentokentoken',response)
        //  this.data = response.data;
        localStorage.setItem("token",response.data.token)
        this.$globaltoken = response.data.token;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });

   this.$refs.probar.start()
     // 检测断网
      window.addEventListener('offline' , () => {
           this.offfun()
        });
      window.addEventListener('online', () => {
           this.onfun()
      });


  },
  methods:{
    retry(){
     // this.$router.push('/')
       window.location.reload();
    },
    offfun(){
        this.promptflag = true;
        this.network = true
        this.reminddata1 = '网络异常'
        this.reminddata2 = '请检查网络后重试'
      //  alert('网络已断开，请检查网络！');
        clearTimeout(this.timer);
         this.$once("hook:beforeDestroy", () => {
        //  alert('首页离线')
          window.removeEventListener('online')
        });

    },
    onfun(){
       console.info('11111网络在线')
        this.promptflag = false;
        this.network = false
      //  clearInterval(this.networkToast);
        this.$once("hook:beforeDestroy", () => {
        //  alert('首页在线')
          window.removeEventListener('online')
        });
    },
    click5(){
          this.promptc = false
          this.timer =  setTimeout(() => {
          this.$router.push('/forward')
        }, 1000);

      this.$once("hook:beforeDestroy", () => {
         clearTimeout(this.timer);
      });
    }
  },

}
</script>

<style lang="stylus" scoped>
@import "../styles/home/index.styl"
</style>
