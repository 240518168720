<template>
    <div class="promptw"  v-show="promptc">
           <div class="prompt">
              <div class="imgwrap">
                   <img  class="imgprompt" :src="imgv" alt="" />
                   <div class="remindwrap">
                       <div class="remindtext1">{{reminddata1}}</div>
                       <div class="remindtext2">{{reminddata2}}</div>
                       <div class="remindtext3" v-show="reminddata1 != '麦克风权限申请'" @click="retryfun">重试</div>
                       <div v-show="reminddata1 == '麦克风权限申请'" class="remindtext31">

                          <div class="remindtext4">拒绝</div>
                          <div class="remindtext5">同意</div>
                       </div>
                    </div>
              </div>
          </div>
    </div>
</template>

<script>
export default {
  name: "prompt",
    props:{
        promptc: Boolean,
        reminddata1: String,
        reminddata2: String
    },
    data(){
        return {
        imgv:'/img/prompt.svg',

        //   promptc:false
        }
  },
  methods:{
    retryfun(){
        this.$emit('retry')
    }
  }
}
</script>

<style lang="stylus" scoped>
.promptw{
    width: 100%
    height: 100%
    position: fixed
    top: 0px
    z-index: 10
    .prompt{
        position:fixed
        width:100vh
        height:100vh
        background-color:rgba(0,0,0,0.2)
        top:0px
        z-index: 10
        .imgwrap{
            position: relative
             width:50%
             height:30%
             margin-left: 25%
             margin-top: 40%
            .imgprompt{
                width: 100%
               /* height: auto
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-83%, -50%)*/
            }
            .remindwrap{
                width: 84%
                height: auto
                position: absolute
                top: 38%
                left: 8%
               /* transform: translate(-50%, -50%)*/
                .remindtext1{
                    color:#4B6CC8
                    font-size:0.4rem
                    text-align:center
                    margin-top: 20px;

                }
                .remindtext2{
                    color:#617ECF
                    font-size:0.25rem
                    margin-top: 10px;
                    margin-bottom: 10px;
                    text-align:center;
                }
                .remindtext3{
                    background-color:#4B6CC8
                    color:#fff
                    width: 100%
                    border-radius: 20px
                    font-size: 0.3rem
                    height: 30px
                    line-height: 30px
                    text-align: center
                }
                .remindtext31{
                    display:flex;
                }
                .remindtext4{
                    background-color:#4B6CC8
                    color:#fff
                    width: 55%
                    border-radius: 20px
                    font-size: 0.3rem
                    height: 30px
                    line-height: 30px
                    text-align: center
                }
                .remindtext5{
                    background-color:#4B6CC8
                    color:#fff
                    width: 55%
                    border-radius: 20px
                    font-size: 0.3rem
                    height: 30px
                    line-height: 30px
                    text-align: center
                        margin-left: 10px;
                }
            }

         }
    }
}
</style>
