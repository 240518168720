var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"meet"},[_c('prompt',{attrs:{"promptc":_vm.promptflag,"reminddata1":_vm.reminddata1,"reminddata2":_vm.reminddata2},on:{"retry":_vm.retry}}),_c('div',{staticClass:"local_player"},[_c('agora',{ref:"ar",attrs:{"channel":_vm.channel,"appid":_vm.appid,"token":_vm.token,"uid":_vm.uid,"autoStart":true,"errorHandler":_vm.handleError,"enableDualStream":""},on:{"user-joined":_vm.handleUserJoin,"user-published":_vm.handleUserPublished,"user-left":_vm.handleUserLeft,"join-success":_vm.handleJoinSuccess,"client-created":_vm.handleClientCreated,"stream-fallback":_vm.handleStreamFallback}},[_c('agora-audio-sender',{ref:"audioSender",attrs:{"mute":_vm.mute},on:{"track-created":_vm.handleAudioTrackCreated}}),_c('agora-audio-receiver',{ref:"audioReceiver",attrs:{"refuse":_vm.refuseList}}),_c('agora-video-sender',{ref:"videoSender",attrs:{"cameraOff":_vm.cameraIsClosed,"customizationPlayer":""},on:{"video-ready":_vm.handleVideoReady,"video-close":_vm.handleVideoClose}}),_c('agora-video-receiver',{attrs:{"customizationPlayer":"","refuse":_vm.refuseList},on:{"video-ready":_vm.handleRemoteVideoReady}})],1)],1),_c('div',{staticClass:"player",class:{
      'screen-share-player': _vm.youAreShareScreening || _vm.otherIsShareScreening
    }},_vm._l((_vm.userIdList.filter(e => e !== _vm.uid)),function(user_id){return _c('div',{key:user_id,staticClass:"user-vision",class:{
        'screen-share-vision': user_id === _vm.shareScreenUID,
        'screen-share-vision-pined':
          user_id === _vm.shareScreenUID && user_id === _vm.pinedUid
      }},[(_vm.playList.find(e => e.uid === user_id))?_c('div',{directives:[{name:"player",rawName:"v-player",value:(_vm.playList.find(e => e.uid === user_id)),expression:"playList.find(e => e.uid === user_id)"},{name:"show",rawName:"v-show",value:(!_vm.streamFallbackList.includes(user_id)),expression:"!streamFallbackList.includes(user_id)"}],staticClass:"player-vision"}):_vm._e()])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showExpandUserList),expression:"showExpandUserList"}],staticClass:"user-list"},[_c('p',{on:{"click":_vm.handleCustom}},[_vm._v("All users in the meeting :")]),_c('ul',_vm._l((_vm.users),function(item,index){return _c('li',{key:index},[_c('voice-dot',{staticClass:"audio-dot-local",attrs:{"level":_vm.audioStatusObj[item.uid || _vm.uid] &&
            _vm.audioStatusObj[item.uid || _vm.uid].level
              ? _vm.audioStatusObj[item.uid || _vm.uid].level
              : 0,"mute":_vm.audioStatusObj[item.uid || _vm.uid] &&
              _vm.audioStatusObj[item.uid || _vm.uid].mute !== false}}),_c('pin-button',{staticClass:"pin-button-local",staticStyle:{"display":"none"},attrs:{"pined":_vm.pined && (item.uid ? _vm.pinedUid === item.uid : _vm.pinedUid === _vm.uid)},on:{"click":function($event){return _vm.handlePinUser(item.uid || _vm.uid)}}}),_vm._v(" "+_vm._s(item.uid || item)+" ")],1)}),0)]),_c('div',{staticClass:"banner"},[_c('mp-button',{class:_vm.microphoneClass,on:{"click":_vm.handleMute}}),(!_vm.inMeeting)?_c('on-call-button',{on:{"click":_vm.handleCall}}):_vm._e(),(_vm.inMeeting)?_c('close-button',{on:{"click":_vm.handleLeave}}):_vm._e(),_c('video-button',{class:_vm.cameraClass,on:{"click":_vm.handleCamera}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }