<template>
    <div>
        <div class="wrap">
            <div id="progress">
                <div id="progress-bar"></div>
            </div>
         </div>
         <div class="progresstext">正在加载{{progressvalue}}</div>
        <!-- <button @click="start()">开始进度</button> <button @click="stop()">结束进度</button>-->

    </div>
</template>

<script>
export default {
  name: "progressbar",
    props:{
        network:Boolean,
    },
  data(){
    return {
       t:null,
       i:0,
       progressvalue:'0%'
    }
  },
  	watch: {
        network(value) {

            if(value){
                console.info('WWWWWWWWWWWWWWw',value)
               clearInterval(this.t);
            }

        }
    },
  methods: {
    start() {
        this.t = setInterval(this.progress, 60);
    },
     progress() {
        const bar = document.getElementById('progress-bar')
        // console.info(bar)
       if (this.i < 100) {
            this.i++;
            if(this.i == 10) {
               //  this.$emit('click1',10)
            } else if(this.i == 30) {
               //  this.$emit('click2',30)
            }else if(this.i == 50) {
                // this.$emit('click3',50)
            }else if(this.i == 70) {
                // this.$emit('click4',70)
            }else if(this.i == 100) {
                 this.$emit('click5',100)
            }
            bar.style.width = this.i + "%";
           // bar.innerHTML = this.i + " %";
           this.progressvalue = this.i + " %";
        } else {
            clearInterval(this.t);
        }
     },
     stop() {
        clearInterval(this.t);
    }

  }
}
</script>


<style lang="stylus" scoped>
.wrap{
    width: 90%
    height: 30px
    position: fixed
    bottom: 1.5rem
    margin-left: 5%
     border: 3px solid #fff
     border-radius: 10px
     box-shadow: 1px 1px 8px #84a4f1
    #progress {
        overflow: hidden
        box-shadow: 1px 1px 8px #84a4f1 inset
        height: 100%;
        border-radius: 10px;
        #progress-bar {
            background-color: #d9534f
            width: 10px
            height: 30px
            line-height: 30px
            border-radius: 10px
            position: absolute
            text-align: center
            color: white
        /*    background-size: 40px 40px background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%,  transparent 25%,  transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,  transparent 75%, transparent)*/
            background-image:linear-gradient(to right, #D5E3FD, #2A65FF);

        }

    }
}
.progresstext{
    -webkit-text-stroke: 0.3px #2A65FF
    text-shadow: 1px 1px 2px #2a65ff
    color:#fff
    font-size: 0.4rem
    position: fixed
    bottom: 1rem
    width: 100%
    text-align: center
}

</style>