import { render, staticRenderFns } from "./mp-button.vue?vue&type=template&id=503a92e8&scoped=true"
import script from "./mp-button.vue?vue&type=script&lang=js"
export * from "./mp-button.vue?vue&type=script&lang=js"
import style0 from "./mp-button.vue?vue&type=style&index=0&id=503a92e8&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "503a92e8",
  null
  
)

export default component.exports