import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Index from "../views/index.vue";
import Forward from "../views/forward.vue";
import Ddigit from "../views/digit.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index
  },
  {
    path: "/forward",
    name: "forward",
    component: Forward
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/digit",
    name: "digit",
    component: Ddigit
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
