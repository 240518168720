<template>
 <div>
         <div class="bg">
                <div class="header">
                    <img  class="imgxy" src="../assets/h5/bank.png" alt="" />
                </div>
                <div class="subbtn">
                    <img  class="imgbtn" @click="forwardfun" src="../assets/h5/btn.svg" alt="" />
                </div>
                 <prompt :promptc="promptflag" :reminddata1="reminddata1" :reminddata2="reminddata2"  @retry="retry"></prompt>
          </div>

    </div>
 </div>

</template>

<script>
import Prompt from "@/components/prompt/main.vue";
export default {
  name: "Forward",
   data(){
    return {
       promptflag:false,
       networkToast:null,
       reminddata1:'',
       reminddata2:''
    }
  },
components: {
    Prompt
  },
  mounted(){
      window.addEventListener('offline' , () => {
           this.offfun()
        });
      window.addEventListener('online', () => {
           this.onfun()
      });
  },
  methods:{
        retry(){
          this.$router.push('/')
        },
        offfun(){
        this.promptflag = true;
        this.reminddata1 = '网络异常'
        this.reminddata2 = '请检查网络后重试'
        // alert('网络已断开，请检查网络！');
       /* this.networkToast = setInterval(() => {
          alert('网络已断开，请检查网络！');
        }, 10000);*/

    },
    onfun(){
       console.info('11111网络在线')
        this.promptflag = false;
   //     clearInterval(this.networkToast);
        this.$once("hook:beforeDestroy", () => {
          window.removeEventListener('online')
        });
    },
    forwardfun(){
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "../styles/home/forward.styl"
</style>
